// themes.css should have 2 classes: .theme-{name} and .dark.theme-{name}
export const defaultTheme = "blue";

// this array could be empty, it's only used by ThemeSelector
export const defaultThemes: { name: string; value: string }[] = [
  { name: "Zinc", value: "zinc" },
  { name: "Blue", value: "blue" },
  { name: "Violet", value: "violet" },
  { name: "None", value: "none" },
];
